export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/login')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/pages/logout')
    },
    {
        path: '/',
        name: 'pos',
        component: () => import('../views/pages/pos')
    }
]