import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import routes from './routes'
import { me } from '../services/operators.service'

Vue.use(VueRouter)
Vue.use(VueMeta, {
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach(async (routeTo, routeFrom, next) => {
    const publicPages = ['/login']
    const auth_page = !publicPages.includes(routeTo.path)
    const session = localStorage.getItem('session_id')

    let _me = await me()
    _me = _me.operator

    if(auth_page && _me && (_me.session_id === 'N/A' || _me.session_id !== session)) {
        return next('/login')
    }

    next()
})

export default router 