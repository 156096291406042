<template>
  <div id='app'>
     <router-view />
  </div>
</template>

<script>
import appConfig from '@/app.config'

export default {
  name: 'maxparking-pos',
  page: {
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
}
</script>

<style>

</style>
